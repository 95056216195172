import styles from "./FirstScreenSocial.module.css"
import {useEffect,useRef} from "react"
import valeria from "../../images/ולריה רמידובסקי ניהול סושיאל.png"

const FirstScreenSocial=()=>{
  


return <>
 <>
<div className={styles.background}>
<div className={styles.description}>לוקחים את הדיגיטל צעד קדימה</div>
<div className={styles.center}><img className={styles.image} src={valeria}/></div>
</div>
</>
</>


}
export default FirstScreenSocial